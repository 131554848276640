import React from 'react';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { fireEvent } from '../utils/events';
import { duplicateProject } from '../utils/requests';
import { 
  DeleteForever, 
  DriveFileRenameOutline, 
  ContentCopy, 
  MoreVert, 
  FolderZip,
  Share,
  Send
} from "@mui/icons-material";
import { isDeveloper } from '../utils/http';
import { TextField } from '@mui/material';


function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}


export default function ContextMenu({projectname}) {
  
  const [anchorMain, setAnchorMain] = useState(null);
  const open = Boolean(anchorMain);

  const [anchorShare, setAnchorShare] = useState(null);
  const openShare = Boolean(anchorShare);

  const [shareduser, setSharedUser] = useState("")

  const handleMainMenuOpen = (event) => {
    event.preventDefault()
    setAnchorMain(event.currentTarget);
  }

  const handleShareMenuOpen = (event) => {
    event.preventDefault()
    setAnchorShare(event.currentTarget);
  }

  const handleDuplicate = () => {
    duplicateProject(projectname)
      .then(() => fireEvent("event:reload"))              // calls the event -> directly apply the effect and reload the list
    handleMainMenuClose()
  }

  const handleShare = () => {
    console.log("Sharing with " + shareduser, isValidEmail(shareduser))
    if (isValidEmail(shareduser)){
      fireEvent("project:share", {name: projectname, sharewith: shareduser}) // calls the event -> show the dialog} 
      //setSharedUser("")
      handleShareMenuClose()
      handleMainMenuClose()
    }
  }

  const handleDownload = () => {
    fireEvent("project:download", projectname) 
    handleMainMenuClose()
  }

  const handleRename = () => {
    fireEvent("event:rename", projectname) // calls the event -> show the dialog
    handleMainMenuClose()
  }

  const handleDelete = () => {
    fireEvent("event:delete", projectname) // calls the event -> show the dialog
    handleMainMenuClose()
  }

  const handleMainMenuClose = () => {
    setAnchorMain(null);
  };

  const handleShareMenuClose = () => {
    setAnchorShare(null);
  }

  return (
    <div onContextMenu={handleMainMenuOpen}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMainMenuOpen}
        onContextMenu={handleMainMenuOpen}
      >
      <MoreVert /> 
      </IconButton>
      <Menu
        id="main-menu"
        anchorEl={anchorMain}
        open={open}
        onClose={handleMainMenuClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
      >
        <MenuList>
            <MenuItem onClick={handleRename}>
                <ListItemIcon>
                    <DriveFileRenameOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleDuplicate}>
                <ListItemIcon>
                    <ContentCopy fontSize="small" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
            </MenuItem>

            <MenuItem 
              onClick={handleShareMenuOpen}
              disabled = {!isDeveloper()}
            >
                <ListItemIcon>
                    <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share with</ListItemText>
            </MenuItem>

            <Menu
              id="share-menu"
              anchorEl={anchorShare}
              open={openShare}
              onClose={handleShareMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem>
                {/* insert a textfield editable */}
     
                <TextField
                  value = {shareduser}
                  onChange={(e) => setSharedUser(e.target.value)}
                  autoFocus
                  type="email"
                  fullWidth
                  variant="standard">
                </TextField>
                <ListItemIcon>
                  <IconButton
                    disabled = {!isValidEmail(shareduser)}
                    onClick={handleShare}>
                  <Send color={isValidEmail(shareduser)?"primary":"gray"}/>
                  </IconButton>
                  
                </ListItemIcon>
                

              </MenuItem>
            </Menu>



            <MenuItem onClick={handleDownload}
              disabled = {!isDeveloper()}
            >
                <ListItemIcon>
                    <FolderZip fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download</ListItemText>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <DeleteForever fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>

            </MenuList>
        </Menu>

      
    </div>
  );
}
