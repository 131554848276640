import React, {useContext, useState} from "react";
import { QgisContext, QgisProject, Tools, printMap } from "@SaferPlaces2023/safer-map";
import {useNavigate} from "react-router-dom"
import Paths from "../utils/paths";
import { fireEvent } from "../utils/events";

import {
    //Tooltip,
    Menu,
    MenuItem,
    Stack,
    Divider,
    IconButton,
    FormLabel,
    Box,
  } from "@mui/material";

import { BigTooltip as Tooltip} from "./BigTooltip";

  import {
    Home,
    ZoomIn,
    ZoomOut,
    ZoomOutMap,
    Adjust,
    RotateLeft,
    RotateRight,
    PanTool,
    CropLandscape,
    Gesture,
    Inbox,
    Bloodtype,
    SquareFoot,
    HighlightAlt,
    FilterHdr,
    Save,
    Extension,
    ArrowBack,
    EggAlt,
    WaterDamage,
    Satellite, 
    SatelliteAlt,
    PictureAsPdf,
    Info,
  } from "@mui/icons-material";

import { user_post } from "../utils/http";
import { MessageBoxShow } from "./StatusMessageBox";

import InfiltrationLayersDialog from "../dialog/InfiltrationLayersDialog";
import BluespotsDialog from "../dialog/BluespotsDialog";  
import { ToolsContext } from "./ToolManager";
import Safer001Dialog from "../dialog/Safer001Dialog";
import CosmoDialog from "../dialog/CosmoDialog";
import Safer003Dialog from "../dialog/Safer003Dialog";
import { DamagePreviewDialog } from "../dialog/DamagePreviewDialog";
import { SearchBox } from "@SaferPlaces2023/sui-vite";
import strftime from "strftime";


const tempBlue = "#3f51b5"

const pressed = {
    backgroundColor: tempBlue,
    color: "white",
    "&:hover": {
        backgroundColor: tempBlue,
        color: "white",
    },
    borderRadius: 7,
}

const unpressed = {
    backgroundColor: "white",
    color: tempBlue, //SPLightTheme.palette.primary.main,
    "&:hover": {
        backgroundColor: "#eeeeee",
        color: "white",
    },
    borderRadius: 7,
}

const disabled = {
    backgroundColor: "white",
    color: "gray", //SPLightTheme.palette.primary.main,
    "&:hover": {
        backgroundColor: "#eeeeee",
        color: "white",
    },
    borderRadius: 7,
}

const ProjectToolbar = (props) => {
    
    const navigate = useNavigate()
    const [activeTool, activateTool] = useContext(ToolsContext)
    const [search, setSearch] = useState("")

    const [menuAnchor, setMenuAnchor] = useState(null)
    const menuOpen = Boolean(menuAnchor)

    const [project, setProject] = useContext(QgisContext)
    const Q = new QgisProject(project, setProject)

    const SimpleView = props.simple || false
    
    
    // a function which returns a string with a maximum length. If the string exceeds the maximum length, it will be truncated and "..." will be added to the end.
    const limitProjectName = (string) => {
        let maxLength = 15
        if (!string) 
            return null
        if (string.length <= maxLength) 
            return string
        return string.substring(0, maxLength) + "..."
    }

    // Back to Projects page
    const handleBack = () => {
        navigate(Paths.MY_PROJECTS)
    }

    const handleSelectTool = (toolName) => {
        activateTool(toolName)
    }

    const handleSearchSelect = (option) => {

        let extent = null

        if (option?.properties?.extent){
            extent = option.properties.extent
        }else if (option?.geometry?.coordinates){
            const coords = option.geometry.coordinates
            const delta = 0.0005
            extent = [coords[0]-delta, coords[1]-delta, coords[0]+delta, coords[1]+delta]
        }

        if (extent){
            Q.zoomToExtent(extent, "EPSG:4326")
        }
    }

    const handleSaveProject = () => {
        const xml = Q.ExportToXml()
        user_post("/api/project",{ xml:xml, overwrite:true})
        .then(response => {
            MessageBoxShow(response?.data?.data?.message,response?.data?.status)}
        )
    }

    const openMenu = (event) => {
        setMenuAnchor(event.currentTarget)
    }
    const closeMenu = () => {
        setMenuAnchor(null)
    }

    return <>
    
        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" />} >

            {/* BACK BUTTON */}
            <Tooltip title="Back to My Projects">
                <IconButton 
                    onClick={handleBack} 
                    variant="contained" 
                    color="primary" > 
                    <ArrowBack />
                </IconButton>
            </Tooltip>

            {/* PROJECT NAME */}
            <Stack justifyContent="center" justifyItems="center" alignItems="center"> 
                <Tooltip title="The project name">
                    <Box width={150} >
                        <FormLabel sx={{fontSize: 17, border:0}}>
                            {limitProjectName(project.projectname)}
                            {/* {activateTool ? activeTool : "Select a tool"} */}
                        </FormLabel>
                    </Box>
                </Tooltip>
            </Stack>

            {/* NAVIGATION TOOLS */}
            <Stack direction="row">
                {/*-----------------------------------------------------------------------------
                |   Save
                ------------------------------------------------------------------------------*/}
                <Tooltip title="Save this project">
                    <IconButton onClick={handleSaveProject} style={unpressed} >
                        <Save sx={{width:32, height:32}}/>
                    </IconButton>
                </Tooltip>

                {/*-----------------------------------------------------------------------------
                |   Home
                ------------------------------------------------------------------------------*/}
                <Tooltip title="Return to initial location">
                    <IconButton onClick={() => {Q.zoomToHome()}} style={unpressed} >
                        <Home />
                    </IconButton>
                </Tooltip>

                {/*-----------------------------------------------------------------------------
                |   Zoom in
                ------------------------------------------------------------------------------*/}

                <Tooltip title="Zoom in">
                    <IconButton onClick={() => project.map.zoomIn()} style={unpressed} >
                        <ZoomIn />
                    </IconButton>
                </Tooltip>

                {/*-----------------------------------------------------------------------------
                |   Zoom out
                ------------------------------------------------------------------------------*/}

                <Tooltip title="Zoom out">
                    <IconButton onClick={() => project.map.zoomOut()} style={unpressed} >
                        <ZoomOut />
                    </IconButton>
                </Tooltip>

                {/*-----------------------------------------------------------------------------
                |   Fullscreen
                ------------------------------------------------------------------------------*/}

                <Tooltip title="Full screen mode">
                    <IconButton onClick={() => project.map.zoomToFullScreen()} style={unpressed} >
                        <ZoomOutMap />
                    </IconButton>
                </Tooltip>

                {/*-----------------------------------------------------------------------------
                |   Rotate counterclockwise
                ------------------------------------------------------------------------------*/}
                <Tooltip title="Rotate counterclockwise">
                    <IconButton onClick={() => project.map.rotate(-10)} style={unpressed} >
                        <RotateLeft />
                    </IconButton>
                </Tooltip>

                {/*-----------------------------------------------------------------------------
                |   Reset rotation
                ------------------------------------------------------------------------------*/}
                <Tooltip title="Reset rotation">
                    <IconButton onClick={() => project.map.getView().setRotation(0)} style={unpressed} >
                        <Adjust />
                    </IconButton>
                </Tooltip>
                {/*-----------------------------------------------------------------------------
                |   Rotate clockwise
                ------------------------------------------------------------------------------*/}
                <Tooltip title="Rotate clockwise">
                    <IconButton onClick={() => project.map.rotate(+10)} style={unpressed} >
                        <RotateRight />
                    </IconButton>
                </Tooltip>
            </Stack>

            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            <Divider orientation="vertical" flexItem />
            {/* ------------------------------------------------------------------------------------------------------------------------ */}

            {/* PROJECT TOOLS */}
            <Stack direction="row"> 
                
                <Stack direction="row">
                    {/*-----------------------------------------------------------------------------
                    |   PAN
                    ------------------------------------------------------------------------------*/}
                    <Tooltip title="Pan"  >
                        <IconButton onClick={() => handleSelectTool(Tools.PAN)} style={activeTool === Tools.PAN ? pressed : unpressed}>
                            <PanTool />
                        </IconButton>
                    </Tooltip>

                    {/*-----------------------------------------------------------------------------
                    |   ZOOM TO SELECTION
                    ------------------------------------------------------------------------------*/}
                    <Tooltip title="Zoom to selection">
                        <IconButton onClick={() => handleSelectTool(Tools.ZOOM_TO_SELECTION)} style={activeTool === Tools.ZOOM_TO_SELECTION ? pressed : unpressed}>
                            <CropLandscape />
                        </IconButton>
                    </Tooltip>

                    

                    {/*-----------------------------------------------------------------------------
                    |   RULER
                    ------------------------------------------------------------------------------*/}
                    <Tooltip title="Ruler" >
                        <IconButton onClick={() => handleSelectTool(Tools.RULER)} style={activeTool === Tools.RULER ? pressed : unpressed}>
                            <SquareFoot />
                        </IconButton>
                    </Tooltip>
                </Stack>

                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Divider orientation="vertical" flexItem />
                {/* ------------------------------------------------------------------------------------------------------------------------ */}

                <Stack direction="row">

                    {/*-----------------------------------------------------------------------------
                    |   RAIN
                    ------------------------------------------------------------------------------*/}
                    <Tooltip hidden={SimpleView} title={"Rain"} >
                        <IconButton onClick={() => handleSelectTool(Tools.RAIN_SELECT)} style={activeTool === Tools.RAIN_SELECT ? pressed : unpressed}>
                            <EggAlt />
                        </IconButton>
                    </Tooltip>

                </Stack>

                <Stack direction="row">

                    {/* ------------------------------------------------------------------------------------------------------------------------ */}
                    <Divider hidden={SimpleView} orientation="vertical" flexItem />
                    {/* ------------------------------------------------------------------------------------------------------------------------ */}




                    {/*-----------------------------------------------------------------------------
                    |   BARRIER
                    ------------------------------------------------------------------------------*/}
                    <Tooltip hidden={SimpleView} title={"Draw barrier"} >
                        <IconButton onClick={() => handleSelectTool(Tools.BARRIER_SELECT)} style={activeTool === Tools.BARRIER_SELECT ? pressed : unpressed}>
                            <Gesture />
                        </IconButton>
                    </Tooltip>

                    {/*-----------------------------------------------------------------------------
                    |   STORAGE TANK
                    ------------------------------------------------------------------------------*/}
                    <Tooltip hidden={SimpleView} title="Draw storage tank" >
                        <IconButton onClick={() => handleSelectTool(Tools.STORAGE_TANK_SELECT)} style={activeTool === Tools.STORAGE_TANK_SELECT ? pressed : unpressed}>
                            <Inbox />
                        </IconButton>
                    </Tooltip>

                    {/*-----------------------------------------------------------------------------
                    |   INFILTRATION
                    ------------------------------------------------------------------------------*/}
                    {/* 
                        //? Così il pulsante apre solo la dialog, senza attivare alcun tool e risulta pressato solo dopo che il tool è 
                        //? stato selezionato all'interno della dialog. In questo caso la dialog NON viene aperta dal ToolManager 
                        // TODO - commentata la fireEvent in ToolManager.js 
                    */}
                    <Tooltip hidden={SimpleView} title="Infiltration Rate" > 
                        <IconButton style={activeTool.name === Tools.INFILTRATION_SELECT ? pressed : unpressed}       //* rememer in this case to use activeTool to get the tool name, since the infiltration tool is activated by passing an object {tool, layers} 
                            onClick={() => fireEvent("infiltration-tool:activate")}
                            >
                            <Bloodtype />
                        </IconButton>
                    </Tooltip>
                </Stack>
                

                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Divider hidden={SimpleView} orientation="vertical" flexItem />
                {/* ------------------------------------------------------------------------------------------------------------------------ */}


                <Stack direction="row">

                    <Tooltip hidden={SimpleView} title="Volume/Damage tools">
                        <IconButton onClick={openMenu} style={(activeTool === Tools.VOLUME || activeTool === Tools.DAMAGE) ? pressed : unpressed}>
                            <HighlightAlt />
                        </IconButton>
                    </Tooltip>
                    
                    <Menu      
                                   
                        anchorEl={menuAnchor} 
                        open={menuOpen} 
                        onClose={closeMenu}>
                        {/*-----------------------------------------------------------------------------
                        |   VOLUME
                        ------------------------------------------------------------------------------*/}
                        <MenuItem onClick={() => {closeMenu(); handleSelectTool(Tools.VOLUME)}}>
                            <Tooltip title="Volume" >
                                <IconButton size="small"  style={activeTool === Tools.VOLUME ? pressed : unpressed}>
                                    <HighlightAlt size="small"/>
                                </IconButton>
                            </Tooltip>
                            Volume
                        </MenuItem>

                        {/*-----------------------------------------------------------------------------
                        |   DAMAGE
                        ------------------------------------------------------------------------------*/}
                        <MenuItem onClick={() => {closeMenu(); handleSelectTool(Tools.DAMAGE)}} >
                            <Tooltip title="Damage" >
                                <IconButton size="small" style={activeTool === Tools.DAMAGE ? pressed : unpressed}>
                                    <HighlightAlt size="small"/>
                                </IconButton>
                            </Tooltip>
                            Damage
                        </MenuItem>
                    </Menu>
                    
                    {/*-----------------------------------------------------------------------------
                    |   SECTION
                    ------------------------------------------------------------------------------*/}
                    <Tooltip hidden={SimpleView} title="Section" >
                        <IconButton value={Tools.SECTION} onClick={() => {handleSelectTool(Tools.SECTION)}} style={activeTool === Tools.SECTION ? pressed : unpressed}>
                            <FilterHdr />
                        </IconButton>
                    </Tooltip>

                    {/*-----------------------------------------------------------------------------
                    |   IDENTIFY
                    ------------------------------------------------------------------------------*/}
                    <Tooltip title="Identify" >
                        <IconButton value={Tools.IDENTIFY} onClick={() => {handleSelectTool(Tools.IDENTIFY)}} style={activeTool === Tools.IDENTIFY ? pressed : unpressed}> 
                            <Info />
                        </IconButton>
                    </Tooltip>


                    {/*-----------------------------------------------------------------------------
                    |   BLUESPOT
                    ------------------------------------------------------------------------------*/}
                    
                    <Tooltip hidden={SimpleView} title="Bluespots" >
                        <IconButton  value={Tools.BLUESPOTS} style={activeTool.name === Tools.BLUESPOT_SELECT ? pressed : unpressed}     //* rememer in this case to use activeTool to get the tool name, since the infiltration tool is activated by passing an object {tool, layers} 
                            onClick={() => { 
                                fireEvent("bluespot-tool:open", true)
                            }} 
                        >
                            <Extension />
                        </IconButton>
                    </Tooltip>
                    
                </Stack>

                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Divider hidden={SimpleView} orientation="vertical" flexItem />
                {/* ------------------------------------------------------------------------------------------------------------------------ */}


            
                <Stack direction="row">
                    {/*-----------------------------------------------------------------------------
                    |   MITIGATION
                    ------------------------------------------------------------------------------*/}
  
                    <Tooltip hidden={SimpleView} title="Mitigations" >
                        <IconButton value={Tools.MITIGATIONS} 
                            onClick={() => handleSelectTool(Tools.MITIGATIONS)} style={activeTool === Tools.MITIGATIONS ? pressed : unpressed}           
                            >
                            <WaterDamage />
                        </IconButton>
                    </Tooltip>



                    {/*-----------------------------------------------------------------------------
                    |   DAMAGE PREVIEW
                    ------------------------------------------------------------------------------*/}

                    {/* <Tooltip hidden={SimpleView}  title={isDmgPreviewActive ? "Damage preview" : "Damage previews are not computed"}>
                        <IconButton value={Tools.DAMAGE_PREVIEW}
                            onClick={() => isDmgPreviewActive ? handleSelectTool(Tools.DAMAGE_PREVIEW) : null} 
                            style={isDmgPreviewActive ? 
                                    ((activeTool === Tools.DAMAGE_PREVIEW) ? pressed : unpressed )
                                    : inactive}           
                            >
                            <InsertChart />
                        </IconButton>
                    </Tooltip> */}

                    
                </Stack>

                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Divider hidden={SimpleView} orientation="vertical" flexItem />
                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Stack direction="row">
                    {/* // -----------------------------------------------------------------------------
                    //|   SAFER001
                    //------------------------------------------------------------------------------ */}
                    <Tooltip hidden={SimpleView} title="Safer001" >
                        <IconButton value="Safer001" onClick={() => { 
                                //attivare il PAN
                                handleSelectTool(Tools.PAN)
                                fireEvent("safer001:open", true)
                            }} style={unpressed}> 
                            <SatelliteAlt/>
                        </IconButton>
                    </Tooltip>
                </Stack>
                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Stack direction="row">
                    {/* // -----------------------------------------------------------------------------
                    //|   SAFER001 - COSMO
                    //------------------------------------------------------------------------------ */}
                    <Tooltip hidden={SimpleView} title="Cosmo" >
                        <IconButton 
                            disabled={true}
                            value="Cosmo-Safer001" 
                            onClick={() => { 
                            //attivare il PAN
                            handleSelectTool(Tools.PAN)
                            fireEvent("cosmo:open", true)
                        }} style={disabled}> 
                        <SatelliteAlt/>
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Stack direction="row">
                    {/* // -----------------------------------------------------------------------------
                    //|   SAFER003
                    //------------------------------------------------------------------------------ */}
                    <Tooltip hidden={SimpleView} title="Safer003" >
                        <IconButton value="Safer003" onClick={() => { 
                                //attivare il PAN
                                handleSelectTool(Tools.PAN)
                                fireEvent("safer003:open", true)
                            }} style={unpressed}> 
                            <Satellite/>
                        </IconButton>
                    </Tooltip>
                </Stack>
                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                 <Divider hidden={SimpleView} orientation="vertical" flexItem />
                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Stack direction="row">
                    {/* // -----------------------------------------------------------------------------
                    //|   Print
                    //------------------------------------------------------------------------------ */}
                    {/* <Tooltip hidden={SimpleView} title="Print" >
                        <IconButton value="Print" onClick={() => {
                               const filepdf = `${project.projectname}-${strftime("%d-%m-%Y@%H.%M")}.pdf` 
                               printMap(project.map, filepdf)
                            }} style={unpressed}> 
                            <PictureAsPdf/>
                        </IconButton>
                    </Tooltip> */}
                </Stack>
                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                 <Divider hidden={SimpleView} orientation="vertical" flexItem />
                {/* ------------------------------------------------------------------------------------------------------------------------ */}
                <Stack direction="row">
                    <SearchBox 
                        sx = {{width: 200, height: 40 }}
                        style={{position:'relative', top:'-20px', left:'4px', verticalAlign:'middle',padding:0, margin:0}}
                        value={search}
                        onChange={(e)=>{setSearch(e.target.value)}}
                        placeholder="Search"
                        onSelect={handleSearchSelect}
                        tags={["house", "street", "locality", "district", "city", "county", "state", "country"]}
                        //tags={["locality", "district", "city", "county", "state", "country"]}
                    />
                </Stack>

            </Stack>

        </Stack>




        {/*-----------------------------------------------------------------------------
        |   DIALOGS
        ------------------------------------------------------------------------------*/}
        <InfiltrationLayersDialog />
        <BluespotsDialog />
        <Safer001Dialog />
        <CosmoDialog />
        <Safer003Dialog />
        <DamagePreviewDialog />
    
    </>
}

export default ProjectToolbar;