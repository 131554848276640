import React, { useState, useContext, useEffect } from "react";

import {
  Box, 
  Stepper, Step, StepLabel,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

import Fab from '@mui/material/Fab';
import ReplyIcon from '@mui/icons-material/Reply';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { forceext } from "../utils/filesystem";
import { addjob } from "../utils/addjob";
import { QgisProject, QgisContext, Tools, addLayerToProject } from "@SaferPlaces2023/safer-map";
import { strftime } from "../utils/time";
import { fireEvent } from "../utils/events";
import { RiverEventStep } from "./RiverEventStep";
import { StorageTankStep } from "./StorageTankStep";
import { ModelStep } from "./ModelStep";
import { NoteStep } from "./NoteStep";
import { len } from "../utils/strings";
import { ToolsContext } from "../widgets/ToolManager";
import { __drive__ } from "../utils/const";


export function RiverWizard(props) {
  
  const [, setActiveTool] = useContext(ToolsContext)

  const [project, ] = useContext(QgisContext);
  const Q = new QgisProject(project)

  const steps = {RIVER: 1, STORAGE: 2, MODEL: 3, NOTE: 4}
  const nSteps = len(steps)

  const [rpoints, setRPoints] = useState(0)
  const [totalVolume, setTotalVolume] = useState(0)
  const [scenario, setScenario] = useState({
    pause:          false,
    name:           strftime("RIVER%H%M%S"),
    rain:           "riverevent.shp",
    duration:       1,
    sand:           "",
    clay:           "",
    activeStep:     1,
    features:       [],
    drawingRiverScenario: false,
    tmax:           2,
    ti:             3600,
    man:            0.02,
    delt:           600,
    nl:             50, 
    dmg:            false, 
    model:          "safer_rain",
    mode:           "local",
  });
  

  const closeWizard = () => {
    setActiveStep(Tools.PAN)
    props.handleClose()
  }
  

  const handleNameChange = (event) => {
    setScenario({...scenario, name: event.target.value} )
  }
  

  const runjob = () => {  
    
    const project_dir = Q.getProjectDir(__drive__)

    const params = {           
      name:        scenario.name,
      type:        "safer_river_flooding",
      project:     Q.getProjectName(),
      filedtm:     Q.getDemPath(__drive__), //filebld,
      filebld:     forceext(Q.getDemPath(__drive__), "bld.tif"),  
      fileobm:     Q.getBuildingsPath(__drive__),
      out:         `${project_dir}/WD_${scenario.name}.tif`,
      
      sand :       Q.getSandPath(__drive__),
      clay :       Q.getClayPath(__drive__),
      ir:          scenario.ir,
      rain:        `${project_dir}/${scenario.rain}`,
      
      sand_fname:  Q.getSandFieldName(),
      clay_fname:  Q.getClayFieldName(),
      ir_fname:    Q.getInfiltrationFieldName(),
      rain_fname:  "v",
      
      storage:     project_dir+"/storagetank.shp",
      storage_selection: scenario.features.filter((f) => f.get("selected")).map((f) => f.getId()),
      
      ga:          false, 
      duration:    scenario.duration, 
      tmax:        scenario.tmax,
      ti:          scenario.ti,
      man:         scenario.man,
      delt:        scenario.delt,
      nl:          scenario.nl, 
      dmg:         scenario.dmg,
      note:        scenario.note,
      model:       scenario.model,
    }
   
    addjob(params)
   
    fireEvent("simulation:start")
  }
  
  
  /** Mette in pausa il wizard e attiva il tool river event */
  const drawrpoints = () => {
    setActiveTool(Tools.RIVER_EVENT_SELECT)
    handlePause()
  }
  
  /** Mette in pausa il wizard e attiva il tool per storage tanks */
  const drawStorageTanks = () => {
    setActiveTool(Tools.STORAGE_TANK_SELECT)
    handlePause()
  }
  
  const handleResume = () => {
    //project.map.activateTool("Pan", true)
    setActiveTool(Tools.PAN)
    setScenario({...scenario, pause: false})
  }
  
  const handlePause = () => {
    setScenario({...scenario, pause: true})
  }
  
  /**
   * getDescription - descrive lo scenario
   */
  const getDescription = () => {
    let river = `${totalVolume} m3 of water from ${rpoints} points.`;
    let model = `model '${scenario.model}'`
    let modelParams = '';
    if(scenario.model === 'untrim'){
      modelParams = `
      Simulation Parameters:
      - TMax: ${scenario.tmax} h;
      - Manning Coefficient: ${scenario.man};
      - Px per cell: ${scenario.nl} px;
      - Delta T: ${scenario.delt} s;
      - Time Shoot Interval: ${scenario.ti} s;`;
    }
    let duration = scenario.model === "untrim" ? `\n- duration ${scenario.duration}h` : ""
    let selectedFeatures = scenario.features.filter(feature => feature.get("selected"))
    let fids = selectedFeatures.map(feature => `\t- Tank${feature.getId()} (${feature.get("v")}m3)`).join("\n")
    let storagetanks = selectedFeatures.length ? `${selectedFeatures.length} storage tanks \n${fids}` : "no storage tanks"
    return "River flooding with:\n- " + [river + duration, storagetanks, model].join("\n- ")+
      (modelParams ? `\n${modelParams.trim()}` : '');
  }
  

  const setActiveStep = (n) => {
    setScenario({...scenario, activeStep: n})
  }
  
  
  const isStepOptional = (step) => {
    return [steps.STORAGE].includes(step);
  }
  
  
  const handleNext = () => {
    if (scenario.activeStep >= nSteps){
      runjob()
      closeWizard()
    } else {
      setActiveStep(scenario.activeStep  + 1)
    }
  }
  

  const handleBack = () => {
    setActiveStep(scenario.activeStep - 1);
  }
  

  const panels = [
    <RiverEventStep scenario={scenario} setScenario={setScenario} onPause={drawrpoints}/>,
    <StorageTankStep scenario={scenario} setScenario={setScenario} onPause={drawStorageTanks}/>,
    <ModelStep scenario={scenario} setScenario={setScenario} />,
    <NoteStep scenario={scenario} setScenario={setScenario} describe={getDescription}/>
  ]
  
  useEffect(() => {

    project.map.getFeaturesOf("riverevent").then(features => {
      let totalVolume = (features.length===0)?0:features.map(f => f.get("v")).reduce((a,b) => a+b)// somma
      setRPoints(features.length)
      setTotalVolume(totalVolume)    
    })

  }, [scenario.activeStep, scenario.pause]) //eslint-disable-line
  
  useEffect(() => {

    if (scenario.rain) {
      const project_dir = Q.getProjectDir() // without __drive__
      addLayerToProject({
        project:project, 
        filename:`${project_dir}/${scenario.rain}`, 
        groupname:"River",
        visible:true,
        expanded:true, 
        cmapname:"riverevent",
        overwrite:true,
        permanent:true,
        //zoomToLayer:true
      })      
    } 
  }, [scenario.rain]) //eslint-disable-line


  return (
    <>
    {
      scenario.pause ? 
      <Fab variant="extended" color="primary" 
      sx={{position:"absolute", zIndex:9999, left:20, top:60}} onClick={handleResume}>
      
      <ReplyIcon sx={{ mr: 1 }} />
      Back to wizard 
      </Fab> 
      : 
      <></>
    }
    
    <Dialog open={!scenario.pause}
      maxWidth="lg" 
      fullWidth
      PaperProps={{sx: {height: 750, width: 900}}}
      >
    <DialogTitle>
      Fluvial scenario:
      <TextField sx={{marginLeft: 1}}
        value = {scenario.name}
        variant="standard"
        onChange={handleNameChange}
        helperText = "job name"
      />
    </DialogTitle>
    
    <DialogContent>
    <Box sx={{ width: "100%" }}>
    <Stepper activeStep={scenario.activeStep - 1}>
    {
      Object.keys(steps).map((label, index) => {
        const labelProps = {};
        if (isStepOptional(index)) {
          labelProps.optional = (
            <Typography variant="caption">Optional</Typography>
            );
          }
          return (
            <Step key={label}>
            <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
            );
          })}
          </Stepper>
          {panels[scenario.activeStep - 1]}
          </Box>
          </DialogContent>
          
          
          <DialogActions sx={{padding: 3}}>
          <Button onClick={closeWizard} color="error">Cancel</Button>
          <Box sx={{ flex: '1 1 auto' }} />
          {(scenario.activeStep > 1) ? 
            <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>Back</Button> 
            : <></>
          }
          <Button 
          startIcon={scenario.activeStep === steps.NOTE ? <PlayArrowIcon /> : <></>}
          onClick={handleNext} 
          variant={scenario.activeStep === steps.NOTE ? "contained" : "outlined"}
          > 
          {scenario.activeStep === steps.NOTE ? 'Run' : 'Next'} 
          </Button>
          </DialogActions>
          
          </Dialog>
          </>
          );
        }